import { POLL_FULL_FRAGMENT } from '@/graphql/_Fragments/Poll/Full';

export const FEED_ITEM_WRAPPER_CHANNEL_FRAGMENT = `
    fragment feedItemWrapperChannelFragment on Channel {
      uid
      name
      description
      imageFileResource {
        uid
        path
        schemaCode
      }
      feed: _channelFeed(
        skip: "%offset%"
        limit: "%first%"
        bookmarksOnly: "%bookmarksOnly%"
        myUid: "%myUid%"
      ) {
          uid
          aggregateKey
          pinnedInTopic
          initiator {
              __typename
              ... on CommunityUser {
                  uid
                  name
                  firstName
                  lastName
                  jobTitle
                  employerName
                  companyRoles(filter: {state: "ACCEPTED"}) {
                      uid
                      state
                      company {
                          uid
                          name
                      }
                  }
                  pictureFileResource {
                      uid
                      path
                      schemaCode
                  }
                  bannerFileResource {
                      uid
                      path
                      schemaCode
                  }
                  _ourConnection(myUid: "%authUser%") {
                      uid
                      linkState
                      connectedUser {
                          name
                          uid
                      }
                  }
                  _isBookmarked(myUid: "%authUser%")
                  _followerCount
              }
              ... on Exhibitor {
                  name
                  uid
                  logoFileResource {
                      uid
                      schemaCode
                      path
                  }
                  bannerFileResource {
                      uid
                      path
                      schemaCode
                  }
                  _isBookmarked(myUid: "%authUser%")
                  _followerCount
              }
              ...on SubEdition {
                  name
                  uid
                  description
                  code
                  logoFileResource {
                      uid
                      path
                      schemaCode
                  }
                  bannerFileResource {
                      uid
                      path
                      schemaCode
                  }
                  _isBookmarked(myUid: "%authUser%")
                  _followerCount
              }
              ... on Channel {
                  uid
                  name
                  description
                  imageFileResource {
                      uid
                      path
                      schemaCode
                  }
                  thumbnailFileResource {
                      uid
                      path
                      schemaCode
                  }
                  _followerCount
                  _isBookmarked(myUid: "%authUser%")
              }
              ... on Topic {
                uid
                name
                _followerCount
                imageFileResource {
                  uid
                  path
                  schemaCode
                }
              }
          }
          _feedItems(orderBy: createdTime_desc) {
              uid
              action
              createdTimestamp
              triggered {
                  __typename
                  ... on Topic {
                    uid
                    name
                    _followerCount
                    imageFileResource {
                      uid
                      path
                      schemaCode
                    }
                  }
                  ... on FeedPost {
                      uid
                      type
                      content
                      _isLiked(myUid: "%authUser%")
                      _likesCount
                      _repostsCount
                      _commentCount
                      _topCommentCount
                      polls {
                        ...pollFullFragment
                      }
                      initiator {
                        uid,
                        __typename
                        ...on Exhibitor {
                          name
                          logoFileResource {
                            uid
                            schemaCode
                            path
                          }
                          _followerCount
                        }
                        ...on CommunityUser {
                          lastName
                          firstName
                          name
                          jobTitle
                          pictureFileResource {
                            uid
                            path
                            schemaCode
                          }
                        }
                        ...on Channel {
                          name
                          thumbnailFileResource {
                            uid
                            path
                            schemaCode
                          }
                          _followerCount
                        }
                        ...on SubEdition {
                          name
                          logoFileResource {
                              uid
                              path
                              schemaCode
                          }
                          _followerCount
                        }
                      }
                      links {
                          uid
                          url
                          title
                          description
                          imageUrl
                      }
                      medias {
                          uid
                          type
                          mediaFileResource {
                              uid
                              fileName
                              path
                              schemaCode
                              fileType
                          }
                      }
                      authorUser {
                          uid
                          name
                          firstName
                          lastName
                      }
                      repostPost {
                          _feedItem {
                              uid
                              wrapper {
                                  uid
                              }
                          }
                          authorUser {
                            uid
                            name
                          }
                          uid
                          type
                          content
                          initiator {
                            uid,
                            __typename,
                            ...on Exhibitor {
                              name
                              logoFileResource {
                                uid
                                schemaCode
                                path
                              }
                              _followerCount
                            }
                            ...on CommunityUser {
                              lastName
                              firstName
                              name
                              jobTitle
                              pictureFileResource {
                                uid
                                path
                                schemaCode
                              }
                              _followerCount
                            }
                            ...on Channel {
                              name
                              thumbnailFileResource {
                                uid
                                path
                                schemaCode
                              }
                              _followerCount
                            }
                            ...on SubEdition {
                              name
                              logoFileResource {
                                uid
                                path
                                schemaCode
                              }
                              _followerCount
                            }
                          }
                          links {
                              uid
                              url
                              title
                              description
                              imageUrl
                          }
                          medias {
                              uid
                              type
                              mediaFileResource {
                                  uid
                                  fileName
                                  path
                                  schemaCode
                                  fileType
                              }
                          }
                      }
                  }
                  ...on SubEdition {
                      name
                      uid
                      description
                      code
                      logoFileResource {
                          uid
                          path
                          schemaCode
                      }
                      _isBookmarked(myUid: "%authUser%")
                      _followerCount
                      startTimestamp
                      endTimestamp
                  }
                  ...on Address {
                      uid
                      address1
                      address2
                      address3
                      city
                      state
                      postalCode
                      phone
                      fax
                      country {
                          name
                          printableName
                      }
                  }
                  ... on CommunityUser {
                      name
                      uid
                      jobTitle
                      bio
                      _isBookmarked(myUid: "%authUser%")
                  }
                  ... on Exhibitor {
                      name
                      uid
                      description
                      logoFileResource {
                          uid
                          schemaCode
                          path
                      }
                      bannerFileResource {
                          uid
                          path
                          schemaCode
                      }
                      _isBookmarked(myUid: "%authUser%")
                      _isFollowed(myUid: "%authUser%")
                      _followerCount
                      _isRecommendedForMe
                  }
                  ...on EditionExhibitorMapping {
                      uid
                      edition {
                          uid
                          name
                          logoFileResource {
                              uid
                              schemaCode
                              path
                          }
                          startTimestamp
                          endTimestamp
                          _isBookmarked(myUid: "%authUser%")
                          _isFollowed(myUid: "%authUser%")
                      }
                  }
                  ... on Product {
                      uid
                      productName: name
                      description
                      images {
                          fullFileResource {
                              uid
                              schemaCode
                              path
                          }
                      }
                      _isBookmarked(myUid: "%authUser%")
                  }
                  ... on Deal {
                      uid
                      dealTitle: title
                      content
                      displayFileResource {
                          uid
                          schemaCode
                          path
                      }
                      _isBookmarked(myUid: "%authUser%")
                  }
                  ... on Session {
                      uid
                      sessionName: name
                      sessionDescription: description
                      imageFileResource {
                          uid
                          schemaCode
                          path
                      }
                      _isBookmarked(myUid: "%authUser%")
                      _isInAgenda(myUid: "%authUser%")
                  }
                  ... on Article {
                      uid
                      name
                      content
                      articleImages {
                          uid
                          path
                          schemaCode
                      }
                  }
                  ... on Speaker {
                      uid
                      firstName
                      lastName
                      jobTitle
                      photoFileResource {
                          uid
                          path
                          schemaCode
                      }
                      _isBookmarked(myUid: "%authUser%")
                  }
                  ... on ExhibitorUrl {
                      uid
                      type
                      icon
                      url
                      deleted
                  }
                  ... on CommunityUserUrl {
                      type
                      url
                  }
                  ... on CommunityUserBookmark {
                      uid
                      link {
                          uid
                          target {
                              __typename
                              ...on SubEdition {
                                  name
                                  uid
                                  description
                                  code
                                  _isBookmarked(myUid: "%authUser%")
                                  _followerCount
                                  autoFollow
                              }
                              ... on CommunityUser {
                                  uid
                                  firstName
                                  lastName
                                  jobTitle
                                  bio
                                  pictureFileResource {
                                      uid
                                      edition {
                                          urlName
                                      }
                                      schemaCode
                                      path
                                  }
                                  _isBookmarked(myUid: "%authUser%")
                              }
                              ... on Speaker {
                                  uid
                                  firstName
                                  lastName
                                  jobTitle
                                  photoFileResource {
                                      uid
                                      path
                                      schemaCode
                                  }
                                  _isBookmarked(myUid: "%authUser%")
                              }
                              ... on Exhibitor {
                                  uid
                                  name
                                  description
                                  logoFileResource {
                                      uid
                                      schemaCode
                                      path
                                  }
                                  _isBookmarked(myUid: "%authUser%")
                              }
                              ... on Session {
                                  uid
                                  name
                                  description
                                  startTime
                                  endTime
                                  bannerFileResource {
                                      uid
                                      path
                                      schemaCode
                                  }
                                  _isBookmarked(myUid: "%authUser%")
                                  _isInAgenda(myUid: "%authUser%")
                              }
                              ... on Product {
                                  uid
                                  name
                                  description
                                  images {
                                      fullFileResource {
                                          uid
                                          schemaCode
                                          path
                                      }
                                  }
                                  _isBookmarked(myUid: "%authUser%")
                              }
                              ... on Deal {
                                  uid
                                  title
                                  content
                                  displayFileResource {
                                      uid
                                      edition {
                                          urlName
                                      }
                                      schemaCode
                                      path
                                  }
                                  _isBookmarked(myUid: "%authUser%")
                              }
                              ... on Article {
                                  uid
                                  name
                                  subtitle
                                  authorName
                                  images {
                                      uid
                                      edition {
                                          urlName
                                      }
                                      schemaCode
                                      path
                                  }
                                  _isBookmarked(myUid: "%authUser%")
                              }
                              ... on Edition {
                                  uid
                                  shortName
                                  logoFileResource {
                                      uid
                                      edition {
                                          urlName
                                      }
                                      schemaCode
                                      path
                                  }
                                  _isBookmarked(myUid: "%authUser%")
                              }
                              ... on Channel {
                                  uid
                                  name
                                  description
                                  imageFileResource {
                                      uid
                                      edition {
                                          urlName
                                      }
                                      schemaCode
                                      path
                                  }
                                  _isBookmarked(myUid: "%authUser%")
                              }
                          }
                      }
                  }
                  ... on CommunityUserConnection {
                      uid
                      user {
                          uid
                          firstName
                          lastName
                          jobTitle
                          pictureFileResource {
                              uid
                              path
                              schemaCode
                          }
                          _isFollowed(myUid: "%authUser%")
                          _ourConnection(myUid: "%authUser%") {
                              uid
                              linkState
                          }
                          _isRecommendedForMe
                      }
                      connectedUser {
                          uid
                          firstName
                          lastName
                          jobTitle
                          pictureFileResource {
                              uid
                              path
                              schemaCode
                          }
                          _isFollowed(myUid: "%authUser%")
                          _ourConnection(myUid: "%authUser%") {
                              uid
                              linkState
                          }
                          _isRecommendedForMe
                      }
                  }
                  ... on Handout {
                      uid
                      name
                      handoutFileResource {
                          uid
                          path
                      }
                      relatedEntity: _relatedEntity {
                        entityType: __typename
                        uid
                      }
                  }
                  ...on CommunityUserInterest {
                      uid
                      interest {
                          uid
                          name
                      }
                  }
                  ... on CommunityUserProfileRole {
                      uid
                      profileRole {
                          name
                      }
                  }
                  ... on FileResource {
                      uid
                      path
                  }
                  ... on Category {
                      uid
                      name
                  }
                  ...on ExhibitorContact {
                      uid
                      exhibitorContactName: name
                      exhibitorContactType: type
                      phone
                      fax
                      email
                  }
              }
          }
          createdTimestamp
          updatedTimestamp
          _isBookmarked(myUid: "%authUser%")
      }
    }
    ${POLL_FULL_FRAGMENT}
`;
